import { Container, Nav, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import './NavigationBar.css';
import home from '../../lib/img/home_icon.svg';
import UserWidget from '../general/UserWidged';
import NavDropdown from 'react-bootstrap/NavDropdown';

function NavigationBar() {
  return (
    <Navbar variant="light" expand="lg" className="">
      <Container className='mw-100 shadow-none'>
        {/* <Navbar.Toggle aria-controls="navbar-expanded" /> */}
        {/* <Navbar.Collapse id="navbar-expanded"> */}
          <Nav className="mr-auto ">
            <NavLink to="/" exact className="nav-guest-item home-image" activeClassName="active">
              <img src={home} height="24px" width="24px"/> 
            </NavLink>
            <NavDropdown
                    id="nav-dropdown-dark-example"
                    title="Пчелопродукция (ассортимент и цены)"
                    menuVariant="dark"
                    style={{color:"#000000"}}
                  >
                    <NavDropdown.Item href="/Honey">Мёд</NavDropdown.Item>
                    <NavDropdown.Item href="/Pollen">Пыльца</NavDropdown.Item>
                    <NavDropdown.Item href="/Wax">Воск</NavDropdown.Item>
                    <NavDropdown.Item href="/Propolis">Прополис</NavDropdown.Item>
                    <NavDropdown.Item href="/RoyalJelly">Маточное молочко (жилье рояль)</NavDropdown.Item>
                    <NavDropdown.Item href="/Perga">Перга</NavDropdown.Item>
                    <NavDropdown.Item href="/DeathOfBees">Подмор пчёл</NavDropdown.Item>
                  </NavDropdown>
            <NavLink to="/benefits-and-applications-of-bee-products" className="nav-guest-item" activeClassName="active">
                    Польза и Применение пчелопродукции 
            </NavLink>
            <NavLink to="/our-apiary" className="nav-guest-item" activeClassName="active">
                    Наша пасека
            </NavLink>
          </Nav>
          {/* <UserWidget /> */}
        {/* </Navbar.Collapse> */}
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
