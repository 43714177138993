import { Container, Navbar } from 'react-bootstrap';
import './FooterBar.css';

function FooterBar() {
  return (
    <div variant="light" expand="lg" className="form-footerbar border-top box-shadow">
      <Container className='mw-100 text-center'>
        {/* <Navbar.Brand> */}

        <div className="d-flex flex-row justify-content-center">
          <div className="box-container-section my-2 ">
            <div className="d-flex">
              <div className="my-4 mx-3 ">
                
              <div className="hrxfooter-title-wrapper" data-target="#footer_sub_menu_store_info" >
                      <span className="hrxfooter-title">Контактная информация</span>
                      <span className="float-xs-right hrxfooter-toggle-icon-wrapper">
                        <span className="navbar-toggler collapse-icons hrxfooter-toggle-icon">
                        </span>
                      </span>
                    </div>
                    <div id="footer_sub_menu_store_info" >
                      <div className="hrxfooter-store-link hrxfooter-address">
                        <b className="material-icons">место</b>
                        <div className="hrx-address">26939 Ovelgönne ot Großenmeer-Nord Alte Pastorei 1</div>
                      </div>
                      <div className="hrxfooter-store-link hrxfooter-call">
                        <b className="material-icons">Пишите вайбер, ватцап</b> <span>+49 152 26522394</span>
                      </div>
                      <div className="hrxfooter-store-link hrxfooter-email">
                        <b className="material-icons">mail </b>
                        <a href="mailto:Paseka000777@gmail.com" className="dropdown">Paseka000777@gmail.com</a>
                      </div>
                    </div>
                
              </div>
              <div className="my-4 mx-3 ">
              <div className="hrxfooter-title-wrapper" data-target="#footer_sub_menu_link34533" >
                      <span className="hrxfooter-title">Полезные ссылки</span>
                      <span className="float-xs-right hrxfooter-toggle-icon-wrapper">
                        <span className="navbar-toggler collapse-icons hrxfooter-toggle-icon">
                        </span>
                      </span>
                    </div>
                    <ul id="footer_sub_menu_link34533" className=" hrxfooter-link-wrapper">
                      <li>
                        <a id="link-cms-page-1-2" className="cms-page-link" href="/Honey" title="Our terms and conditions of delivery">Пчелопродукция</a>
                      </li>
                      <li>
                        <a id="link-cms-page-2-2" className="cms-page-link" href="/benefits-and-applications-of-bee-products" title="Legal notice">Польза и Применение пчелопродукции</a>
                      </li>
                      <li>
                        <a id="link-cms-page-3-2" className="cms-page-link" href="/our-apiary" title="Terms and conditions of use">Наша пасека</a>
                      </li>
                      {/* <li>
                        <a id="link-cms-page-4-2" className="cms-page-link" href="" title="Learn more about us">О нас</a>
                      </li>
                      <li>
                        <a id="link-static-page-contact-2" className="cms-page-link" href="" title="Use our form to contact us">Связаться с нами</a>
                      </li> */}
                    </ul>
                
              </div>
              <div className="my-4 mx-3 " style={{minWidth:"8em"}}>
              <div className="hrxfooter-store-time-info-box">
                      <div className="hrxfooter-title-wrapper" data-target="#footer_sub_menu_store_time" >
                        <span className="hrxfooter-store-title hrxfooter-title">Рабочее время</span>
                        <span className="float-xs-right hrxfooter-toggle-icon-wrapper">
                          <span className="navbar-toggler collapse-icons hrxfooter-toggle-icon">
                          </span>
                        </span>
                      </div>
                      <div id="footer_sub_menu_store_time" className=" hrxfooter-time-wrapper">
                        <div className="hrxfooter-open-time-wrapper">
                          <div className="hrxfooter-opentime-lable">Пн - Сб : </div>
                          <div className="hrxfooter-opentime-content">08:00 - 20:00 </div>
                        </div>
                        <div className="hrxfooter-open-time-wrapper">
                          <div className="hrxfooter-opentime-lable">Вс :</div>
                          <div className="hrxfooter-opentime-content hrxfooter-opentime-closed">Закрито</div>
                        </div>
                      </div>
                    </div>
              </div>
            </div>
          </div>
        </div>
{/*           
        <a href="https://Devrubu.com" target="_blank" className='nav-item'>
          © DevRuBu - розробник порталу
        </a> */}
        {/* </Navbar.Brand> */}
      </Container>
    </div>
    // </div>
  );
}

export default FooterBar;
