import { Container } from 'react-bootstrap';
import { Redirect, Route, Switch } from 'react-router';
import PrivateRoute from '../general/PrivateRoute';
import { FooterBar, MainNavigationBar, NavigationBar } from '../Navbar/Index';
import { useAuth } from '../general/ProvideAuth';
import NotFound from '../../pages/NotFound/NotFound';
import UserProfile from '../../pages/UserProfile/UserProfile';
import Home from '../../pages/Main/Home';
import BeeProducts from '../../pages/bee/BeeProducts';
import BenefitsApplications from '../../pages/bee/BenefitsApplications';
import ProductsForBeekeepers from '../../pages/bee/ProductsForBeekeepers';
import Honey from '../../pages/BeeProducts/Honey';
import Pollen from '../../pages/BeeProducts/Pollen';
import Wax from '../../pages/BeeProducts/Wax';
import Propolis from '../../pages/BeeProducts/Propolis';
import RoyalJelly from '../../pages/BeeProducts/RoyalJelly';
import Perga from '../../pages/BeeProducts/Perga';
import DeathOfBees from '../../pages/BeeProducts/DeathOfBees';
import OurApiary from '../../pages/bee/OurApiary';
import './MainLayout.css';

function MainLayout() {
  const auth = useAuth();
  return (
    <>
      <Switch>
        <Route exact path="/">
          <MainNavigationBar />
          <Home />
        </Route>
        <Route exact path="/Honey">
          <NavigationBar />
          <Honey />
        </Route>
        <Route exact path="/Pollen">
          <NavigationBar />
          <Pollen />
        </Route>
        <Route exact path="/Wax">
          <NavigationBar />
          <Wax />
        </Route>
        <Route exact path="/Propolis">
          <NavigationBar />
          <Propolis />
        </Route>
        <Route exact path="/RoyalJelly">
          <NavigationBar />
          <RoyalJelly />
        </Route>
        <Route exact path="/Perga">
          <NavigationBar />
          <Perga />
        </Route>
        <Route exact path="/DeathOfBees">
          <NavigationBar />
          <DeathOfBees />
        </Route>


        {/* <Route exact path="/bee-products">
          <NavigationBar />
          <BeeProducts />
        </Route> */}
        <Route exact path="/benefits-and-applications-of-bee-products">
          <NavigationBar />
          <BenefitsApplications />
        </Route>
        <Route exact path="/our-apiary">
          <NavigationBar />
          <OurApiary />
        </Route>
        {/* <Route exact path="/dataSet-registry-issuances">
          <NavigationBar />
          <Issuances />
        </Route>
        <Route exact path="/dataSet-registry-issuances/:page?">
          <NavigationBar />
          <Issuances />
        </Route>
        <Route exact path="/dataSet-registry-issuances/doci/:id?">
          <NavigationBar />
          <IssuanceView />
        </Route>
        <Route exact path="/dataSet-registry-refusals">
          <NavigationBar />
          <Refusals />
        </Route>
        <Route exact path="/dataSet-registry-refusals/:page?">
          <NavigationBar />
          <Refusals />
        </Route>
        <Route exact path="/dataSet-registry-refusals/docr/:id?">
          <NavigationBar />
          <RefusalView />
        </Route>
        <Route exact path="/dataSet-registry-annulments">
          <NavigationBar />
          <Annulments />
        </Route>
        <Route exact path="/dataSet-registry-annulments/:page?">
          <NavigationBar />
          <Annulments />
        </Route>
        <Route exact path="/dataSet-registry-annulments/doca/:id?">
          <NavigationBar />
          <AnnulmentView />
        </Route>
        <Route exact path="/dataSet-Diagram-Analysis-Count">
          <NavigationBar />
          <DiagramAnalysisCount />
        </Route>
        <Route exact path="/dataSet-Diagram-Analysis-Enterprise">
          <NavigationBar />
          <DiagramAnalysisEnterprise />
        </Route> */}
        <Route>
          <NavigationBar />
          <div className='App'>
            <NotFound />
          </div>
        </Route>
      </Switch>
      <FooterBar />
    </>
  );
}

export default MainLayout;
