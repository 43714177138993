import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router';
import { Container } from 'react-bootstrap';
import { useAuth } from '../../components/general/ProvideAuth';
import { useHistory } from 'react-router';
import './Registry.css';
import  Breadcrumbs from '../../services/Breadcrumbs';
import API from '../../services/api';
import Pagination from '../../components/Pagination/Pagination';
import PaginationWrapper from '../../components/Pagination/PaginationWrapper';
import isFile from '../../lib/img/isFile.svg';
import  moment from 'moment';
import RegistrySearchBar from '../../components/SearchBar/RegistrySearchBar';
import {NormalizeDateView} from '../../services/NormalizeDateService';
import { 
  massagesFilter, 
  massagesErrorFilter,
  massagesErrorFilterNotFound
} from '../../services/messages';
import { Form } from 'react-bootstrap';
import { pageSizeList } from '../../services/PageSize';
import Carousel from 'react-bootstrap/Carousel';


import img130 from '../../lib/img/130.jpg';
import img112 from '../../lib/img/img112.jpg';
import img124 from '../../lib/img/img124.jpg';
import img125 from '../../lib/img/img125.jpg';
// import img126 from '../../lib/img/img126.jpg';
import img127 from '../../lib/img/img127.jpg';
// import img128 from '../../lib/img/img128.jpg';
import img129 from '../../lib/img/img129.jpg';

function Registry() {
  
  return (
    <div className="App">
      <Container className='mw-100'>
        <Breadcrumbs />
      <div className="d-flex flex-row justify-content-center ">
      <div className=''>
      <div style={{margin: "auto"}}>
      <Carousel data-bs-theme="dark">
  <Carousel.Item className='carousel-img-container-size'>
    <img className='carousel-img-size'  alt="900x500" src={img130} />
  </Carousel.Item>
  <Carousel.Item className='carousel-img-container-size'>
    <img className='carousel-img-size'   alt="900x500" src={img112} />
  </Carousel.Item>
  <Carousel.Item className='carousel-img-container-size'>
    <img className='carousel-img-size'   alt="900x500" src={img124} />
  </Carousel.Item>
  <Carousel.Item className='carousel-img-container-size'>
    <img className='carousel-img-size'   alt="900x500" src={img125} />
  </Carousel.Item>
  <Carousel.Item className='carousel-img-container-size'>
    <img className='carousel-img-size'   alt="900x500" src={img127} />
  </Carousel.Item>
  <Carousel.Item className='carousel-img-container-size'>
    <img className='carousel-img-size'  alt="900x500" src={img129} />
  </Carousel.Item>
</Carousel>
      </div>
          <div className="box-container-section my-2 ">
          <p>Наша пасека — это истинное царство пчел, 
где каждый улей наполнен активной жизнью и бесконечной работой на благо природы.  <br/>
Здесь, среди пышной природы и аромата цветущих лугов, 
наши трудолюбивые пчелы собирают самые ценные нектары и полезные соки, 
чтобы создать для вас настоящие деликатесы здоровья.  <br/><br/>
Наша команда пчеловодов с любовью ухаживает за каждым ульем, 
обеспечивая оптимальные условия для процветания и развития наших тружениц.  <br/><br/>
Приглашаем вас отправиться в увлекательное путешествие по нашей пасеке и окунуться в удивительный мир пчеловодства, 
где каждый шорох и жужжание наполнены волшебством и заботой о природе.</p>
<p>
Здесь вы найдете уютные ульи, переполненные свежим медом и полезными продуктами пчеловодства, 
а также сможете познакомиться с процессом сбора и обработки нектаров.  <br/><br/>
Наша пасека — это не просто место производства мёда, 
это целый мир, где каждая пчела играет свою неповторимую роль в целостной системе природы.  <br/>
Мы приглашаем вас окунуться в атмосферу настоящего пчеловодства и ощутить гармонию и благополучие, 
которые дарит нам эта удивительная часть природы.</p>
           
          </div>
      </div>
        </div>
      </Container>
    </div>

  );

}

export default Registry;
