import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router';
import { Container } from 'react-bootstrap';
import { useAuth } from '../../components/general/ProvideAuth';
import { useHistory } from 'react-router';
import './Registry.css';
import  Breadcrumbs from '../../services/Breadcrumbs';
import API from '../../services/api';
import Pagination from '../../components/Pagination/Pagination';
import PaginationWrapper from '../../components/Pagination/PaginationWrapper';
import isFile from '../../lib/img/isFile.svg';
import  moment from 'moment';
import RegistrySearchBar from '../../components/SearchBar/RegistrySearchBar';
import {NormalizeDateView} from '../../services/NormalizeDateService';
import { 
  massagesFilter, 
  massagesErrorFilter,
  massagesErrorFilterNotFound
} from '../../services/messages';
import { pageSizeList } from '../../services/PageSize';
import Perga from '../../lib/img/perg850.jpg';

function Registry() {

  return (
    <div className="App">
      <Container className='mw-100'>
      <Breadcrumbs />
                    
      <img loading="lazy" src={Perga} 
                    alt="Perga" class="featured-image teaser ls-is-cached lazyloaded"/>
      <div className="d-flex flex-row justify-content-center">
          <div className="box-container-section my-2 ">
            <div className="d-flex">
              <div className="my-4 mx-3 item-container-border-top text-center">
                <h2>
                  {/* <Link to="/dataSet-registry-issuances"> */}
                  {/* </Link> */}
                  </h2>
                  <p>100 грамм - 8€</p>
              </div>
              <div className="my-4 mx-3 item-container-border-top text-center">
                <h2>
                  {/* <Link to="/dataSet-registry-refusals"> */}
                    {/* </Link> */}
                  </h2>
                  <p>500 грамм - 30€</p>
              </div>
              <div className="my-4 mx-3 item-container-border-top text-center">
                <h2>
                  {/* <Link to="/dataSet-registry-refusals"> */}
                    {/* </Link> */}
                  </h2>
                  <p>1 кг - 60€</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>

  );

}

export default Registry;
