import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
 import  Breadcrumbs from '../../services/Breadcrumbs';
import './Home.css';

function Home() {

  return (
    <div className="App">
      <Container className='mw-100'>
      {/* <div className="d-flex flex-row justify-content-center"> */}
      <div className="justify-content-center">
      {/* <div className='w-75'> */}
          <div className="box-container-section my-2">
          <p>В нашем магазине вы найдете широкий ассортимент натуральных продуктов пчеловодства, 
          которые приготовлены с любовью и заботой. <br/><br/>
          Наш мёд, пыльца, воск, прополис, маточное молоко, 
          перга и подмор пчёл — это не только вкусные и полезные продукты, 
          но и символы здорового образа жизни и гармонии с природой.  <br/><br/>
          Мы гордимся качеством наших товаров и стремимся предоставить нашим клиентам только самое лучшее, 
          чтобы помочь им достичь здоровья и благополучия.  <br/><br/>
          Приглашаем вас познакомиться с нашим ассортиментом и насладиться 
          всеми преимуществами натуральных продуктов пчеловодства.</p>
           
          </div>
      {/* </div> */}
        </div>
      </Container>
    </div>

  );

}

export default Home;
