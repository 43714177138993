import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ProvideAuth from './components/general/ProvideAuth';
import PrivateRoute from './components/general/PrivateRoute';
import Account from './pages/Auth/Account';
import { AdminLayout, UserLayout, MainLayout} from './components/Layout/Index';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './ProbaPro.css';
import './e-Ukraine.css';

function App() {
  return (
    <ProvideAuth>
      <Router>
        <Switch>
          {/* <PrivateRoute path="/admin">
            <AdminLayout />
          </PrivateRoute>
          <PrivateRoute path="/user">
            <UserLayout />
          </PrivateRoute>
          <Route path="/account">
            <Account />
          </Route> */}
          <Route path="/">
            <MainLayout />
          </Route>
        </Switch>
      </Router>
    </ProvideAuth>
  );
}

export default App;
