import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router';
import { Container } from 'react-bootstrap';
import { useAuth } from '../../components/general/ProvideAuth';
import { useHistory } from 'react-router';
import './Registry.css';
import  Breadcrumbs from '../../services/Breadcrumbs';
import API from '../../services/api';
import Pagination from '../../components/Pagination/Pagination';
import PaginationWrapper from '../../components/Pagination/PaginationWrapper';
import isFile from '../../lib/img/isFile.svg';
import  moment from 'moment';
import RegistrySearchBar from '../../components/SearchBar/RegistrySearchBar';
import {NormalizeDateView} from '../../services/NormalizeDateService';
import Forbs from '../../lib/img/ac0e5d6c-c6c4-4866-b290-089c54255deb.jpg';
import Linden from '../../lib/img/0eff917e-b6c0-4d6f-bb63-261a2e6d115e.jpg';
import Rape from '../../lib/img/6824fec6-3013-448c-923d-9a9ca31b84af.jpg';
import { 
  massagesFilter, 
  massagesErrorFilter,
  massagesErrorFilterNotFound
} from '../../services/messages';
import { pageSizeList } from '../../services/PageSize';

function Registry() {

  return (
    <div className="App">
      <Container className='mw-100'>
      <Breadcrumbs />
      <div>Заказ  от 5 кг 9€ кг</div>
      <div className="d-flex flex-row justify-content-center">
          <div className="box-container-section my-2 ">
            <div className="d-flex">
              <div className="my-4 mx-3 item-container-border-top text-center">
                <h2>
                  {/* <Link to="/dataSet-registry-issuances"> */}
                    <img loading="lazy" height={250} src={Forbs}
                    alt="Разнотравье вместе с акацией" class="teaser ls-is-cached lazyloaded"/>
                  {/* </Link> */}
                  </h2>
                  <p>Разнотравье вместе с акацией</p>
                  <p>5€ 500 грамм  1.5 кг 15€</p>
              </div>
              <div className="my-4 mx-3 item-container-border-top text-center">
                <h2>
                  {/* <Link to="/dataSet-registry-refusals"> */}
                    <img loading="lazy" height={250} 
                            src={Linden}
                            alt="Липа" class=" teaser ls-is-cached lazyloaded"/>
                    {/* </Link> */}
                  </h2>
                  <p>Липа</p>
                  <p>5 € 500 грамм 1.5 кг 15€</p>
              </div>
              <div className="my-4 mx-3 item-container-border-top text-center">
                <h2>
                  {/* <Link to="/dataSet-registry-refusals"> */}
                    <img loading="lazy" height={250} 
                    src={Rape}
                    alt="Рапс" class=" teaser ls-is-cached lazyloaded"/>
                    {/* </Link> */}
                  </h2>
                  <p>Рапс</p>
                  <p>5 € 500 грамм 1.5 кг 15€</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>

  );

}

export default Registry;
