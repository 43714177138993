import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router';
import { Container } from 'react-bootstrap';
import { useAuth } from '../../components/general/ProvideAuth';
import { useHistory } from 'react-router';
import './Registry.css';
import  Breadcrumbs from '../../services/Breadcrumbs';
import API from '../../services/api';
import Pagination from '../../components/Pagination/Pagination';
import PaginationWrapper from '../../components/Pagination/PaginationWrapper';
import isFile from '../../lib/img/isFile.svg';
import  moment from 'moment';
import RegistrySearchBar from '../../components/SearchBar/RegistrySearchBar';
import {NormalizeDateView} from '../../services/NormalizeDateService';
import { 
  massagesFilter, 
  massagesErrorFilter,
  massagesErrorFilterNotFound
} from '../../services/messages';
import { pageSizeList } from '../../services/PageSize';

function Registry() {
  
  return (
    <div className="App">
     
        <Container className='mw-100'>
        <Breadcrumbs />
          <div className="d-flex flex-row justify-content-center ">
            <div className=''>
                <div className="box-container-section my-2 text-justify">
                <p>Мед — это не только вкусный деликатес, но и ценный природный продукт, обладающий множеством полезных свойств.
                <br/><br/> Его уникальный состав обогащен витаминами, минералами и антиоксидантами, которые способствуют укреплению иммунитета, повышению энергии и улучшению общего состояния организма.
                <br/><br/> Мед также используется в косметологии и медицине благодаря своим антибактериальным и заживляющим свойствам.
                </p>
      <p>Пыльца — это настоящий природный биостимулятор, обогащенный белками, витаминами, ферментами и аминокислотами.
        <br/><br/> Ее регулярное употребление способствует укреплению иммунитета, повышению выносливости и улучшению общего состояния организма.
        <br/><br/> Пыльца также полезна для кожи и волос, она способствует их увлажнению и омоложению.
      </p><p>Воск — это универсальный материал с широким спектром применения.
        <br/><br/> Он используется в косметологии и медицине для изготовления мазей, кремов и лекарственных препаратов.
        <br/><br/> Воск также используется в пчеловодстве для создания сотового материала и защиты ульев от влаги и холода.
      </p><p>Прополюс — это уникальный продукт, обладающий антимикробными, противовоспалительными и антиоксидантными свойствами.
        <br/><br/> Его активные вещества помогают укрепить иммунитет, бороться с инфекциями и улучшить общее состояние организма.
        <br/><br/> Прополюс используется в медицине для лечения простудных заболеваний, ран и ожогов.
      </p><p>Маточное молоко — это ценный продукт, который вырабатывается пчелами-матками и служит источником питательных веществ для личинок.
        <br/><br/> Оно содержит множество витаминов, минералов и белков, которые способствуют укреплению иммунитета, повышению энергии и улучшению здоровья кожи и волос.
      </p><p>Перга — это продукт, получаемый из цветочного пыльца и используемый в пищевой и медицинской промышленности.
        <br/><br/> Она богата витаминами, аминокислотами и ферментами, которые способствуют укреплению иммунитета, повышению энергии и улучшению пищеварения.
        <br/><br/> Перга также полезна для кожи и волос, она способствует их увлажнению и омоложению.
      </p><p>Подмор пчел — это продукт, получаемый из мертвых трупов пчел и используемый в медицинской и косметической промышленности.
        <br/><br/> Он богат белками, минералами и аминокислотами, которые способствуют регенерации клеток, увлажнению кожи и борьбе с воспалениями.
      </p>
                </div>
            </div>
        </div>
      </Container>
    </div>

  );

}

export default Registry;
