import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router';
import { Container } from 'react-bootstrap';
import { useAuth } from '../../components/general/ProvideAuth';
import { useHistory } from 'react-router';
import './Registry.css';
import  Breadcrumbs from '../../services/Breadcrumbs';
import API from '../../services/api';
import Pagination from '../../components/Pagination/Pagination';
import PaginationWrapper from '../../components/Pagination/PaginationWrapper';
import isFile from '../../lib/img/isFile.svg';
import  moment from 'moment';
import RegistrySearchBar from '../../components/SearchBar/RegistrySearchBar';
import {NormalizeDateView} from '../../services/NormalizeDateService';
import { 
  massagesFilter, 
  massagesErrorFilter,
  massagesErrorFilterNotFound
} from '../../services/messages';
import { Form } from 'react-bootstrap';
import { pageSizeList } from '../../services/PageSize';

function Registry() {
  const [issuances, setIssuances] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [dateFilterStart, setDateFilterStart] = useState(moment().startOf('year').format('DD.MM.YYYY'));
  const [dateFilterEnd, setDateFilterEnd] = useState(moment().format('DD.MM.YYYY'));
  const [searchRequests, setSearchRequests] = useState(null);
  const [errorFilterMessage, setErrorFilterMessage] = useState("");
  const [filterMessage, setFilterMessage] = useState("");
  const history = useHistory();
  const { page } = useParams();
  const filds = ["Номер документа", "Код ЄДРПОУ", "Заявник", "Роботи", "Об'єкт благоустрою", "Адреса об’єкту"];
  
  const auth = useAuth();

  useEffect(() => {
    async function fetchData() {
      if (page =="doci") {
        history.push(`/dataSet-registry-issuances`);
      }
      await dataGet();
    }

    fetchData();
  }, [page,pageSize]);

  async function dataGet() {
    console.log("pageSize");
    console.log(pageSize);
    const result = await API.get('/issuance', {
      params: {
        page: page,
        pageSize: pageSize,
        dateStart: dateFilterStart,
        dateEnd: dateFilterEnd,
        searchWords: searchRequests
      },
    }).then((res) => {
      setFilterMessage(massagesFilter(
        dateFilterStart,
        dateFilterEnd,
        searchRequests));
      setErrorFilterMessage("");
      if (res?.data) return res?.data;
      else return [];
    }).catch((error) => {
      if (error && (error?.response?.status && error?.response?.status == 404)) {
        setErrorFilterMessage(massagesErrorFilterNotFound());
      }else{
        setErrorFilterMessage(massagesErrorFilter());
      }
      setFilterMessage("");
      return [];
    });

    if (result && result.length > 0) {
      setIssuances(result);
      
      const countResult = await API.get('/issuance/count', {
        params: {
          dateStart: dateFilterStart,
          dateEnd: dateFilterEnd,
          searchWords: searchRequests
        },
      });
      if (countResult.data) {
        setPageCount(Math.ceil(countResult.data.count / pageSize));
      }
    }else{
      setIssuances([]);
      setPageCount(0);
    }
  };

  const handleSelect = (id) => {
    history.push(`/dataSet-registry-issuances/doci/${id}`);
  };

  return (
    <div className="App">
      <Container className='mw-100'>
        <Breadcrumbs />
        <div className="d-flex flex-column">
          <div className="header-bar">
            <div className="header-text">
              <p>Дозволи</p>
            </div>
          </div>
          {RegistrySearchBar(dateFilterStart, setDateFilterStart,
                dateFilterEnd, setDateFilterEnd, errorFilterMessage, 
                filterMessage, setSearchRequests, filds, dataGet)}
          <div className="mr-3 ml-3 my-2">
            <div className="my-2">
              <table className="table-responsive">
                <thead>
                  <tr>
                    {auth && auth.user? <th className="wd-th-small-3"><div className='column-th'>В</div></th>:<></> }
                    <th className="wd-th-small"><div className='column-th'>Номер документа</div></th>
                    <th className="wd-th-small"><div className='column-th'>Дата документа</div></th>
                    <th className="wd-th-small"><div className='column-th'>Код ЄДРПОУ</div></th>
                    <th className="wd-th-middle"><div className='column-th'>Заявник</div></th>
                    <th className="wd-th-big-vh10"><div className='column-th'>Роботи</div></th>
                    <th className="wd-th-big-vh6"><div className='column-th'>Об'єкт благоустрою</div></th>
                    <th className="wd-th-big-vh6"><div className='column-th'>Адреса об’єкту</div></th>
                    <th className="wd-th-small"><div className='column-th'>Район робіт</div></th>
                    <th className="wd-th-middle"><div className='column-th'>Дата початку дії документа</div></th>
                    <th className="wd-th-middle"><div className='column-th'>Дата закінчення дії документа</div></th>
                  </tr>
                </thead>
                <tbody>
                  {issuances.map((i) => (
                    <tr key={i.id}>
                      {auth && auth.user? <td> {i.existsFile == true && <img src={isFile} height="24px" width="24px"/>} </td>:<></> }
                      <td className="registry-item-click" onClick={() => handleSelect(i.parentDocument)}>{i.number}</td>
                      <td>{NormalizeDateView(i.issued)}</td>
                      <td>{i.applicantIdentifier}</td>
                      <td>{i.applicantName}</td>
                      <td>{i.type}</td>
                      <td>{i.name}</td>
                      <td>{i.addressDescription}</td>
                      <td>{i.addressAdminUnit}</td>
                      <td>{NormalizeDateView(i.validFrom)}</td>
                      <td>{NormalizeDateView(i.validThrough)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {PaginationWrapper(page, pageCount, "/dataSet-registry-issuances/", pageSizeList, setPageSize)}
              </div>
          </div>
        </div>
      </Container>
    </div>
  );

}

export default Registry;
