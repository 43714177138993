import React from "react";
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import './Breadcrumbs.css';

const routeConfig = [
    {
      path: "/",
      breadcrumb: 'Головна'
    },
    {
      path: "/Honey",
      breadcrumb: 'Мёд'
    },
    {
      path: "/Pollen",
      breadcrumb: 'Пыльца'
    },
    {
      path: "/Wax",
      breadcrumb: 'Воск'
    },
    {
      path: "/Propolis",
      breadcrumb: 'Прополис'
    },
    {
      path: "/RoyalJelly",
      breadcrumb: 'Маточное молочко (жилье рояль)'
    },
    {
      path: "/Perga",
      breadcrumb: 'Перга'
    },
    {
      path: "/DeathOfBees",
      breadcrumb: 'Подмор пчёл'
    },
    {
      path: "/our-apiary",
      breadcrumb: 'Наша пасека'
    },
    {
      path: "/benefits-and-applications-of-bee-products",
      breadcrumb: 'Польза и Применение пчелопродукции'
    },
    {
      path: "/User",
      breadcrumb: 'Профіль Користувача'
    },
    {
      path: "/Admin/Users",
      breadcrumb: 'Користувачі'
    },
    {
      path: "/Admin",
      breadcrumb: 'Адміністратор'
    }
  ];
  

const Breadcrumbs = withBreadcrumbs(routeConfig)(({ breadcrumbs }) => (
    <React.Fragment>
        <ol className="breadcrumb">
            {breadcrumbs.map(({ breadcrumb, match }) =><li key={match.url}><a href={match.url} >{breadcrumb}</a></li>)}
        </ol>
    </React.Fragment>
  ));
  
export default Breadcrumbs;

