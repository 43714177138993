import { Container, Nav, Navbar } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
import './NavigationBar.css';
import UserWidget from '../general/UserWidged';
import NavDropdown from 'react-bootstrap/NavDropdown';

function MainNavigationBar() {
  return (
    <div className='header-bar'>
      <div className='mw-100'>
        <div className='form-guest-bg-gradient'>
          <Navbar variant="light" expand="lg" className="">
            <Container className='mw-100 shadow-none'>
              {/* <Navbar.Toggle aria-controls="navbar-expanded" /> */}
              {/* <Navbar.Collapse id="navbar-expanded"> */}
                <Nav className="mr-auto">
                  <NavDropdown
                    id="nav-dropdown-dark-example"
                    title="Пчелопродукция (ассортимент и цены)"
                    menuVariant="dark"
                    style={{color:"#000000"}}
                  >
                    <NavDropdown.Item href="/Honey">Мёд</NavDropdown.Item>
                    <NavDropdown.Item href="/Pollen">Пыльца</NavDropdown.Item>
                    <NavDropdown.Item href="/Wax">Воск</NavDropdown.Item>
                    <NavDropdown.Item href="/Propolis">Прополис</NavDropdown.Item>
                    <NavDropdown.Item href="/RoyalJelly">Маточное молочко (жилье рояль)</NavDropdown.Item>
                    <NavDropdown.Item href="/Perga">Перга</NavDropdown.Item>
                    <NavDropdown.Item href="/DeathOfBees">Подмор пчёл</NavDropdown.Item>
                  </NavDropdown>
                  <NavLink to="/benefits-and-applications-of-bee-products" className="nav-guest-item" activeClassName="active">
                    Польза и Применение пчелопродукции 
                  </NavLink>
                  <NavLink to="/our-apiary" className="nav-guest-item" activeClassName="active">
                  Наша пасека
                  </NavLink>
                </Nav>
                {/* <UserWidget /> */}
              {/* </Navbar.Collapse> */}
            </Container>
          </Navbar>
          <Container className='navbar-guest-title mw-100 d-flex justify-content-center shadow-none align-items-center'>
            <Navbar className='align-middle'>
              <Link to="/" className='text-center'>
                <h1>ПчёлоАC</h1>
                <h2>+49 152 26522394</h2>
                <div style={{marginTop:"1em", fontSize:"0.925rem", textAlign:"right"}}>
                  Андрей Скрыпник</div>
              </Link>
            </Navbar>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default MainNavigationBar;
